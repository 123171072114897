<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @input="getList"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 요청기간 -->
          <c-datepicker
            :required="true"
            :range="true"
            defaultStart="-1M"
            defaultEnd="1M"
            label="요청기간"
            name="patrolDates"
            v-model="searchParam.patrolDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 부서 -->
          <c-dept
            type="search"
            label="LBLDEPT"
            name="deptCd"
            :isFirstValue="false"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-12">
        <!-- 부서별 점검결과 건수 -->
        <c-card title="부서별 점검결과 건수" class="cardClassDetailForm bodyOverflow-none">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <apexchart 
                ref="chart" 
                :height="chartHeight" 
                type="bar"
                :width="chart.chartWidth"
                :options="chart.chartOptions" 
                :series="chart.series"></apexchart>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'patrolGraph',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      chartHeight: 500,
      seriesData: [],
      searchParam: {
        plantCd: null,
        deptCd: null,
        useFlag: 'Y',
        patrolDates: [],
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      chart: {
        chartOptions: {
          colors: [],
          title: {
            text: ''
          },
          chart: {
            type: 'bar',
            // dropShadow: {
            //   enabled: true,
            //   color: '#000',
            //   top: 18,
            //   left: 7,
            //   blur: 10,
            //   opacity: 0.2
            // },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          labels: {
            style: {
              colors: [],
              fontSize: '12px'
            }
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            labels: {
              style: {
                colors: [],
                fontSize: '12px'
              },
              formatter: function (val) {
                return val
              }
            },
            categories: [],
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            show: false
          },
        },
        series: [
          {
            name: '',
            data: [],
          }
        ],
        chartWidth: '100%',
      },
      colors: ['#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.graph.result.url;
      this.getList();
      this.setSize();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.chart.chartOptions.xaxis, 'categories', this.$_.map(_result.data, 'deptName'));
        this.$set(this.chart.series[0], 'data', this.$_.map(_result.data, 'cnt'));

        if (this.chart.chartOptions.xaxis.categories && this.chart.chartOptions.xaxis.categories.length > 0) {
            let colors = this.colors.slice(0,this.chart.chartOptions.xaxis.categories.length)
            this.$set(this.chart.chartOptions, 'colors', colors)
            // this.$set(this.chart.chartOptions.xaxis.labels.style, 'colors', colors)
        }

        this.chart.chartWidth = '100%';
        this.$refs['chart'].refresh();
      },);
    },
    setSize() {
      let height = window.innerHeight - 324;
      height = height >= 400 ? height : 400
      this.chartHeight = height;
    },
  }
};
</script>
